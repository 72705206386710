const isProd = process.env.NEXT_PUBLIC_APP_CONTEXT === 'production';

export const API_URL = {
  APPLY: 'apply',
  CLAIM: 'apply/claim',
  PROGRAMS: 'programs',
  UNIVERSITIES: 'universities/universities',
  UNIVERSITY: 'universities',
  HBOS: 'universities/HBOs',
  JOBS: 'vacancies/jobs',
  VACANCIES: 'vacancies',
  INTERNSHIPS: 'vacancies/internships',
  TRAINEESHIPS: 'vacancies/traineeships',
  COMPANIES_POPULAR_EMPLOYER: 'companies/popular_employers',
  COMPANIES: '/companies',
  GENERAL_CITIES: '/general/city',
  PROVINCES: '/general/province',
  CITIES: 'cities',
  AUTOSEARCH: 'search/autosearch',
  CATEGORIES: '/general/categories',
  STUDIES: '/studies',
  SEARCH: 'search/general',
  EVENTS: 'events',
  SIGN_UP: 'auth/signup',
  LOGIN: 'auth/login',
  LOGIN_VIA_SOCIAL_NETWORK: 'auth/login/social_network',
  REFRESH_TOKEN: 'auth/refresh',
  USER: 'user/me',
  DELETE_ACCOUNT: 'user/me',
  FORGOT_PASSWORD: 'auth/forgot_password',
  RESTORE_PASSWORD: 'auth/restore_password',
  VERIFY_EMAIL: 'auth/verify-email',
  CHANGE_PASSWORD: '/user/me/password',
  FAVORITE: '/favorite',
  ADS: '/video',
  STUDENT_SOCIETIES: '/companies/student-societies',
  STUDENT_HOUSING: '/companies/student-housing',
  LIFESTYLE: '/companies/lifestyle',
  REVIEW: '/review',
  BLOG_API: `${process.env.NEXT_PUBLIC_BLOG_LINK}/wp-json`,
  BLOG_POSTS: `wp/v2/posts`,
  ENTITY_DESCRIPTION: '/page-metadata',
  CONTACT_US: '/feedback/contact-us',
};

export const APP_ROUTES = {
  // old pages to gids
  GIDS: '/gids',
  CITIES: '/gids/steden',
  COMPANIES: '/gids/bedrijven',
  SEARCH: '/gids/zoeken',
  STUDENT_HOUSING: '/gids/kamer-huren',
  STUDENT_SOCIETIES: '/gids/studentenverenigingen',

  LIFESTYLE: '/gids/studentenleven',
  ACTIVITY: '/activiteiten',
  HEALTH: '/gezondheid',
  SPORT: '/sport',

  HOME: '/',
  WERKGEVER: '/inhuren',
  HIRE_BURAU: '/studenten-uitzendbureau',
  JOBBOARD: '/jobboard',
  WERVING: '/werving',
  UITZENDBUREAU: '/uitzendbureau',
  SIGN_UP: '/signup',
  LOGIN: '/login',
  LOGOUT: '/logout',
  FORGOT_PASSWORD: '/reset-password',
  VERIFY_EMAIL: '/verify-email',
  BLOG: '/blog',
  ONBOARDING: '/onboarding',
  PROFILE: '/profile',
  PROFILE_EDIT: '/edit-profile',
  CONTACTS: '/contact',
  PRIVACY_STATEMENT: '/privacy-statement',
  TERMS_OF_USE: '/terms-of-use',
  ABOUT_US: '/over-ons',
  ALL_VACANCIES: '/vacatures',
  JOBS: '/vacatures',
  BEST_VACANCY: '/best-betaalde-bijbanen',
  THUISWERKEN: '/thuiswerken',
  VAKANTIEWERK: '/vakantiewerk',
  SUBJECTS: '/proefpersoon-worden',
  FINANCIAL_BLOG: '/geld',

  COUPONS: '/coupons',
  // SIGN_IN: '/signin',
  PROFILE_FAVORITES: '/profile/favorites',
  PROFILE_CLOSE: '/profile/close',
  SUBSCRIPTIONS: '/partner-worden',
  PARTNERS: '/partners',
  PARTNERS_SIGN_UP: `https://${isProd ? '' : 'dev-'}partners.studenten.nl/sign-up`,
  PARTNERS_LOGIN: `https://${isProd ? '' : 'dev-'}partners.studenten.nl/login`,
};

export const CITY_SUB_ROUTES = [
  APP_ROUTES.STUDENT_SOCIETIES,
  APP_ROUTES.JOBS,
  APP_ROUTES.STUDENT_HOUSING,

  // disabled as they produces 404 links in sitemap
  // APP_ROUTES.ACTIVITY,
  // APP_ROUTES.SPORT,
  // APP_ROUTES.HEALTH,
];

export const JOB_FUNCTIONS = {
  ZORG: '/zorg-bijbaan',
  CALLCENTER: '/callcenter-bijbaan',
  BIJLES_GEVEN: '/bijles-bijbaan',
  CHAUFFEUR: '/chauffeur-bijbaan',
  SCHOONMAAK: '/schoonmaak-bijbaan',
  LOGISTIEK: '/logistiek-bijbaan',
  ADMINISTRATIEVE: '/administratieve-bijbaan',
  BEZORGER: '/bezorger-bijbaan',
  ICT: '/ict-bijbaan',
  HORECA: '/horeca-bijbaan',
};

export const EXTERNAL_LINKS = {
  COMPANY_ADMIN_PLATFORM: 'https://partners.studenten.nl/',
  MAIL_TO_ADMIN: 'mailto:admin@studenten.nl',
  BLOG: 'https://studenten.nl/blog',
};
